import React from "react"
import { graphql, Link } from "gatsby";
import Layout from "../../components/Layout"
import { getImage } from "gatsby-plugin-image"
import { CardBody, CardDetail, CardImage, CardSubTitle, CardTitle, Genre, GenreList } from "../../styles/Global.styled";

const TrackTemplate = ({ data }) => {
  const track = data.markdownRemark
  if (!track) {
    // Some pages are auto generated that are not markdown, but they are still generated... so return null
    return null
  }
  if (track.frontmatter.type !== "track") {
    // Some pages are auto generated that are markdown but not tracks, but they are still generated... so return null
    return null
  }
  const title = track.frontmatter.title
  const releaseDate = new Date(track.frontmatter.releaseDate)
  const duration = new Date(0)
  duration.setSeconds(track.frontmatter.duration)
  const durationString = duration.getMinutes() + ":" + duration.getSeconds().toString().padStart(2, '0')
  const genres = track.frontmatter.genres
  const spotifyLink = track.frontmatter.spotifyEmbed.code || ""
  const bandCampLink = track.frontmatter.bandCampEmbed.code || ""
  // const soundCloudLink = track.frontmatter.soundCloudEmbed.code || ""
  const soundCloudLink = ""
  return (
    <Layout title={title}>
      <CardDetail>
        <CardTitle>{title}</CardTitle>
        <CardSubTitle>
          <Link to="/tracks">Back</Link>
          <p>Released: {releaseDate.toDateString()}</p>
          <p>Duration: {durationString}</p>
          <GenreList>Genres</GenreList>
          {
            genres.map(genre => {
              return (
                <Genre>{genre}</Genre>
              )
            })
          }
        </CardSubTitle>
        <CardBody>
          <b>Track Notes:</b>
          <p dangerouslySetInnerHTML={{ __html: track.html }} />
          <b>Listen Online:</b>
          <p style={{maxWidth: "16rem"}} dangerouslySetInnerHTML={{ __html: spotifyLink }} />
          <p style={{maxWidth: "16rem"}} dangerouslySetInnerHTML={{ __html: bandCampLink }} />
          <p style={{maxWidth: "16rem"}} dangerouslySetInnerHTML={{ __html: soundCloudLink }} />
        </CardBody>
        <CardImage
          alt="cover art"
          image={getImage(track.frontmatter.cover)}
        />
      </CardDetail>
    </Layout>
  )
}

export default TrackTemplate

export const trackQuery = graphql`
  query getTrackByFieldSlug($fields__slug: String) {
    markdownRemark(
      fields: { slug: { eq: $fields__slug } }
      frontmatter: { type: { eq: "track" } }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
        releaseDate
        duration
        type
        cover {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, width: 150, formats: AUTO)
          }
        }
        author
        genres
        spotifyEmbed {
          code
        }
        bandCampEmbed {
          code
        }
      }
      id
      html
    }
  }
`
